<template>
  <div class="input-text">
    <!-- @slot before slot -->
    <slot name="before" />

    <div class="content">
      <input
        ref="input"
        v-maska="mask"
        placeholder=""
        :class="addInputElClasses('text')"
        :aria-placeholder="placeholder"
        :value="value"
        v-bind="$attrs"
        :id="name"
        v-on="listeners"
        @input="onInput"
        @maska="onMaska"
      />

      <component
        :is="hasLabelTag ? 'label' : 'span'"
        :class="[ 'placeholder', { '-with-value': !!value }]"
        :aria-hidden="!hasLabelTag"
        v-bind="hasLabelTag ? { for: name } : {}"
      >
        {{ placeholder }}
      </component>

      <img
        v-if="status && status !== 'none'"
        class="status"
        :src="statusSrc"
      />

      <div class="shadow" />

      <slot name="extra-content"/>
    </div>

    <!-- @slot after slot -->
    <slot name="after" />
  </div>
</template>

<script>
import { maska } from 'maska'
import InputProps from './props/Input'
import InputTextProps from './props/InputText'

export default {
  name: 'InputText',

  mixins: [ InputProps, InputTextProps ],

  directives: { maska },

  props: {
    /**
     * Defines mask. It uses maska directive
     * datatypes.
     */
    mask: [ String, Object, Array ],
  },

  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    statusSrc () {
      return this.status === 'positive'
        ? require('@assets/done-check-green.svg')
        : require('@assets/icn_warning.svg')
    }
  },

  methods: {
    onInput (event) {
      const { value } = event.target
      this.$emit('input', value)

      if (!this.mask) this.$emit('update:raw', value)
    },

    onMaska () {
      if (this.mask) this.$emit('update:raw', this.$refs.input.getAttribute('data-mask-raw-value'))
    }
  },
}
</script>

<style lang="scss">
.input-text {
  $horizontal-padding: 20px;
  $placeholder-size: 14px;
  $placeholder-focus-scale: 11 / 14;

  & > .content {
    @extend %input-container;
    display: flex;
    position: relative;

    & > .status {
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);

      user-select: none;
      pointer-events: none;

      width: 24px;
      height: 24px;
    }

    & > .placeholder {
      @extend %placeholder;

      pointer-events: none;
      position: absolute;
      top: 12px;
      left: $horizontal-padding;
      transition: transform .2s, font-size .2s;
      transform: translateY(10px) translateZ(0);
    }

    & > .text:focus + .placeholder,
    & > .placeholder.-with-value
    {
      transform: translateY(0) translateZ(0);

      @include responsive (xs-mobile, mobile) {
        font-size: 9px;
      }
    }

    & > .text.-with-validation.-invalid + .placeholder {
      color: $negative-color;
      opacity: 1;
    }

    & > .text {
      $border-size: 1px;

      padding: 30px $horizontal-padding 15px $horizontal-padding;
      flex: 1;
      position: relative;
      transition: border .2s;
      border: $border-size solid transparent;

      @extend %input-text;

      &, &:focus {
        border: 0;
        outline: 0;
      }

      &:invalid {
        box-shadow:none;
      }

      &.-with-validation.-invalid {
        $left-padding: $horizontal-padding - $border-size;

        border: $border-size solid $negative-color;
        padding: 30px $horizontal-padding 15px $left-padding;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        box-shadow: 0 0 0px 1000px white inset;
      }
    }

    & > .shadow {
      @extend %input-shadow;
    }

    & > .text:focus ~ .shadow { opacity: 1; }
  }
}
</style>
