<template>
  <input-text
    class="input-password"
    autocomplete="on"

    :type="view ? 'text' : 'password'"
    :name="name"
    :placeholder="placeholder"
    :has-label-tag="hasLabelTag"
    :has-validation="hasValidation"
    :value="value"
    :errors="errors"

    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- @slot before content -->
    <slot slot="before" name="before" />

    <template name="extra-content">
      <template slot="extra-content">
        <img
          v-show="canView"
          slot="extra-content"
          :src="require('@assets/eye-hidden.svg')"
          class="toggle-view -hidden"
          @click="onToggleViewClick"
        />

        <img
          v-show="!canView"
          slot="extra-content"
          :src="require('@assets/eye-show-visible.svg')"
          class="toggle-view"
          @click="onToggleViewClick"
        />
      </template>

      <slot name="extra-content" />
    </template>

    <template slot="after" >
      <div v-if="hasStrengthMeter" class="strength-meter">
        <div
          v-for="meterKey in strengthKeys"
          :key="meterKey"
          class="meter"
          :style="getBackgroundColorStyle(meterKey, backgroundColors)"
        />

        <span class="message">{{ strengthMessage }}</span>
      </div>

      <!-- @slot after content -->
      <slot name="after" />
    </template>
  </input-text>
</template>

<script>
import { PasswordMeter } from 'password-meter'

import InputText from './InputText'
import InputProps from './props/Input'
import InputTextProps from './props/InputText'

export default {
  components: { InputText },

  mixins: [ InputProps, InputTextProps ],

  data: () => ({ canView: false }),

  props: {
    /**
     * hides password ignoring internal state
     */
    alwaysHide: Boolean,

    /**
     * Defines wheter the password has the strength
     * meter
     */
    hasStrengthMeter: Boolean,

    /**
     * Defines the strength needed for each password
     * meter rises and the message text.
     * It uses PasswordMeter library rules of strength
     * definition
     * (https://github.com/HamedFathi/PasswordMeter)
     */
    strengths: {
      type: Object,
      default: () => ({
        weak: {
          strength: '100',
          message: 'Senha fraca',
          color: '#FF4B8C'
        },
        moderate: {
          strength: '180',
          message: 'Pode melhorar',
          color: '#FFC24B'
        },
        strong: {
          strength: '_',
          message: '',
          color: '#63E1A5'
        }
      }),
    },

    defaultMeterColor: {
      type: String,
      default: 'rgba(18, 30, 72, 0.05)'
    }
  },

  computed: {
    view () {
      return this.alwaysHide !== this.canView
    },

    strengthKeys () {
      return Object.keys(this.strengths)
    },

    strengthCheckerOptions () {
      return Object.entries(this.strengths)
        .reduce((options, [ key, { strength } ]) => {
          return ({ ...options, [strength]: key })
        }, {})
    },

    strengthChecker () {
      return new PasswordMeter({}, this.strengthCheckerOptions)
    },

    strengthCheckerResult () {
      return this.strengthChecker.getResult(this.value)
    },

    strengthStatus () {
      return (this.strengthCheckerResult || {}).status
    },

    hasStrength () {
      const trueUntil = this.strengthKeys
        .findIndex(strengthKey => strengthKey === this.strengthStatus)

      return this.strengthKeys.reduce((hasStrength, strengthKey, i) => {
        return { ...hasStrength, [strengthKey]: trueUntil >= i }
      }, {})
    },

    strengthMessage () {
      return (this.strengths[this.strengthStatus] || {}).message
    },

    backgroundColors () {
      return Object.entries(this.strengths)
        .reduce((options, [ status, { color } ]) => {
          return {
            ...options,
            [status]: this.hasStrength[status] ? color : this.defaultMeterColor
          }
        }, {})
    }
  },

  methods: {
    getBackgroundColorStyle (meterKey, backgroundColors) {
      return { 'background-color': backgroundColors[meterKey] }
    },

    onToggleViewClick () {
      if (!this.alwaysHide) this.canView = !this.canView
    },
  },

  watch: {
    alwaysHide (hide) {
      if (!hide) this.canView = false
    }
  }
}
</script>

<style lang="scss">
.input-password {
  & .toggle-view {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;

    cursor: pointer;

    width: 24px;
    height: 24px;
    color: #121E48;
    opacity: 0.5;
    transition: opacity 200ms;
    transform-style: preserve-3d;

    &:hover { opacity: 0.7; }

    &.-hidden {
      margin-top: 4px;
      margin-right: -2px;
    }
  }

  & > .strength-meter {
    margin-top: 10px;
    display: flex;
    align-items: center;

    & > .meter {
      height: 5px;
      max-width: 100px;
      flex: 1;
      border-radius: 2.5px;
      transition: background-color .2s;

      @include responsive (xs-mobile, mobile) {
        max-width: 80px;
      }

      &:not(:last-child) {
        margin-right: 2px;
      }
    }

    & .message {
      margin-left: 15px;
      opacity: 0.3;
      color: #121E48;
      font-family: $font-family-nunito-regular;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
      min-height: 22px;
    }
  }
}
</style>
