import { isServerError } from '@modules/validation/serverError'

export default {
  props: {
    /**
     * Name of the input
     */
    name: {
      type: String,
      required: true
    },

    /**
     * Input value (two-way data binding)
     */
    value: {
      type: [ String, Object, Array ],
      required: true,
    },

    /**
     * Has validation element
     */
    hasValidation: Boolean,

    /**
     * Internal placeholder value
     */
    placeholder: String,

    /**
     * validation errors
     */
    errors: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    addInputElClasses (baseClass) {
      return [ baseClass, { '-with-validation': this.hasValidation, '-invalid': this.errors.length } ]
    }
  },

  computed: {
    clientErrors () {
      return this.errors.filter(isServerError)
    }
  }
}
