<template>
  <div class="select">
    <!-- @slot before -->
    <slot name="before" />

    <div class="content">
      <select
        v-if="isMobile"
        class="m-select"
        :id="name"
        @change="onMobileInput"
      >
        <option
          v-for="option in objOptions"
          :key="option.id"
          :value="option.id"
        >
          {{ option.label }}
        </option>
      </select>

      <v-select
        :class="[addInputElClasses('v-select'), { '-has-value': !!value }]"
        :options="allOptions"
        :clearable="false"
        autocomplete="off"
        :autoscroll="false"
        :filterable="false"
        :searchable="false"
        v-model="model"
        v-on="listeners"
        @input="onInput"
      />

      <div class="shadow" />
      <!-- @slot extra content -->
      <slot name="extra-content"/>
    </div>

    <!-- @slot after -->
    <slot name="after" />
  </div>
</template>

<script>
import VSelect from 'vue-select'

import { MediaQuery } from '@convenia/mixins'
import InputProps from './props/Input'

export default {
  mixins: [ InputProps, MediaQuery ],

  components: { VSelect },

  data: () => ({ model: null, initial: true }),

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (this.initial || this.isMobile) {
          this.model = value || { id: undefined, label: this.placeholder }
        }

        this.initial = false
      }
    }
  },

  props: {
    options: {
      type: Array,
      default: () => []
    },

    trackBy: {
      type: String,
      default: 'id'
    },

    displayBy: {
      type: String,
      default: 'label'
    },

    nullable: String
  },

  computed: {
    objOptions () {
      return this.options.map(option => {
        return typeof option === 'object'
          ? { id: option[this.trackBy], label: option[this.displayBy] }
          : { id: option, label: option }
      })
    },

    allOptions () {
      const placeholder = this.placeholder && this.model === undefined
        ? [ { [this.trackBy]: undefined, [this.displayBy]: this.placeholder } ]
        : []

      const nullable = this.nullable
        ? [ { [this.trackBy]: null, [this.displayBy]: this.nullable } ]
        : []

      return [ ...placeholder, ...nullable, ...this.objOptions ]
    },

    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
  },

  methods: {
    onMobileInput ($e) {
      this.$emit('input', $e.target.value)
      this.$nextTick(() => this.$emit('update:raw', this.model))
    },

    onInput (value) {
      const { id } = value || {}
      this.$emit('input', id)
      this.$emit('update:raw', value)
    }
  }
}
</script>

<style lang="scss">
$vs-border-radius: 5px;
$vs-border-width: 0px;
$vs-dropdown-max-height: 240px;
$vs-dropdown-box-shadow: $input-shadow;
$vs-state-active-bg: rgba($base-text-color, 0.1);
$vs-selected-border-color: rgba($base-text-color, 0.9);

@import "vue-select/src/scss/vue-select.scss";

.select {
  & > .content {
    overflow:visible;
    position: relative;
    $action-padding: 20px;

    & > .shadow {
      @extend %input-shadow;
    }

    & > .v-select {
      border-radius: 5px;

      &.-has-value .vs__selected {
        height: 100%;
        opacity: 1;
        position: relative;
        font-family: $font-family-nunito-regular;
        color: rgba($base-text-color, 0.9);
        font-size: 14px;
        padding-top: 20px;
        -webkit-font-smoothing: antialiased;
        text-transform: capitalize;

        &::before {
          // I'm so, so very sorry, but we have to send this to
          // production today at 6pm, ok?
          content: 'Cargo';
          display: inline-block;
          user-select: none;
          pointer-events: none;
          position: absolute;
          font-family: $font-family-nunito-bold;
          color: rgba($base-text-color, 0.5);
          top: 12px;
          font-size: 11px;
          text-transform: uppercase;
        }
      }

      &:hover .vs__actions::after { opacity: 0.7; }

      &.vs--open .vs__selected-options .vs__selected {
        transform: translateY(-50%) !important;
      }

      &.vs--open .vs__actions::after {
        transform: rotate(180deg);
        opacity: 0.7;
      }

      &.-with-validation.-invalid:not(.vs--open) {
        $border-size: 1px;
        border: $border-size solid $negative-color;
      }

      .vs__search {
        opacity: 0;
      }

      & .vs__dropdown-menu .vs__dropdown-option--selected,
      & .vs__dropdown-menu .vs__dropdown-option--highlight {
        background-color: rgba($base-text-color, 0.00);
        color: rgba($base-text-color, 0.7);
      }

      & .vs__search::placeholder,
      & .vs__dropdown-toggle,
      & .vs__dropdown-menu {
        cursor: pointer;
        background: #fff;
        padding: 0;
      }

      & .vs__search::placeholder,
      & .vs__dropdown-toggle {
        height: 60px;
      }

      & .vs__search {
        cursor: pointer;
      }

      & .vs__actions {
        padding-right: 20px;

        & > .vs__open-indicator { display: none; }

        &::after {
          display: block;
          content: '';
          width: 24px;
          height: 24px;
          background: url('~@assets/chevron-down.svg');
          transform: rotate(0deg);
          transition: transform 300ms ease;
          opacity: 0.5;
          transition: opacity 200ms ease, transform 300ms ease;
        }

        padding-right: $action-padding;
      }

      & .vs__selected-options {
        height: 100%;
        padding: 0;
      }

      & .vs__selected {
        @extend %placeholder;

        padding-left: $action-padding;
        transition: transform .2s, opacity .2s;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
      }

      &.vs--open .vs__selected, &.vs--searching .vs__selected {
        transform: translateY(calc(-10px - 50%));
        display: flex;
      }

      & .vs__search {
        @extend %input-text;
        padding: 30px 20px 15px 20px;
      }

      & .vs__dropdown-menu {
        box-shadow: $input-shadow;
      }

      & .vs__dropdown-option {
        height: 60px;
        @extend %placeholder;
        display: flex;
        align-items: center;
        opacity: 1;
        color: rgba($base-text-color, 0.7);
        background-color: rgba($base-text-color, 0.0);
        transition: background-color 300ms ease;

        &:not(:last-of-type) {
          border-bottom: 1px solid rgba($base-text-color, 0.1);
        }

        &:hover {
          background-color: rgba($base-text-color, 0.05);
          color: rgba($base-text-color, 0.9);
        }
      }
    }

    & > .m-select {
      position: absolute;
      width: 100%;
      z-index: 10;
      height: 60px;
      opacity: 0;
    }
  }
}
</style>
