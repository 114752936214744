<template>
  <validation-provider
    ref="provider"
    v-slot="{ errors, failedRules, valid, dirty }"
    :name="name"
    :rules="field.validation"
    slim
    mode="lazy"
  >
    <div class="builder-field">
      <component
        :is="field.component"
        :name="name"
        :value="value"
        :style="field.style"
        :errors="errors"
        :has-validation="hasValidation && !field.noValidation"
        :raw.sync="raw"
        :status="getFieldStatus({ valid, dirty })"
        class="field-component"
        v-bind="getFieldAttrs(field)"
        @input="value => $emit('input', value)"
        @update:raw="value => $emit('update:raw', value)"
      />

      <div v-if="!field.noValidation" :class="['validation', { '-show': !valid }]">
        <span class="msg">
          {{ getValidationMsg({ errors, failedRules }) }}
        </span>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import { ValidationProvider } from 'vee-validate'

export default {
  mixins: [ MediaQuery ],

  components: { ValidationProvider },

  data: () => ({ raw: null }),

  props: {
    value: [ String, Object, Array ],
    field: Object,
    name: String,
    hasValidation: Boolean,
  },

  watch: {
    raw: {
      immediate: true,
      deep: true,
      handler: 'validate'
    }
  },

  methods: {
    getFieldAttrs (field) {
      const {
        hasValidation,
        component,
        name,
        style,
        validateRaw,
        ...fieldAttrs
      } = field

      return {
        ...fieldAttrs,
        placeholder: this.isMobile && field.placeholderMobile
          ? field.placeholderMobile
          : field.placeholder
      }
    },

    getFieldStatus ({ dirty, valid }) {
      return dirty
        ? valid ? 'positive' : 'negative'
        : 'none'
    },

    getValidationMsg ({ errors = [], failedRules = {} }) {
      const showRequiredMsg = 'required' in failedRules

      const validationMsg = errors.length && !showRequiredMsg && this.field.validationMsg
        ? this.fieldSchema.validationMsg
        : errors[0]

      return errors.length || this.field.forceError
        ? validationMsg || errors[0] || ''
        : ''
    },

    validate () {
      this.$nextTick(() => {
        const { provider } = this.$refs
        const { validateRaw } = this.field

        const value = validateRaw ? this.raw : this.value
        provider.syncValue(value)
      })
    }
  }
}
</script>

<style lang="scss">

.builder-field {

  & > .field-component {
    margin-bottom: 0 !important;
  }

  & > .validation {
    display: flex;
    align-items: center;
    overflow: hidden;

    height: 100%;
    max-height: 0px;
    margin-top: 8px;
    margin-bottom: 6px;
    transform: scale(1, 0.0);

    opacity: 0;
    transition: max-height 300ms, margin-bottom 300ms, opacity 400ms, transform 200ms;

    &.-show {
      transform: scale(1, 1);
      opacity: 1;
      max-height: 24px;
      margin-bottom: 16px;
    }

    & > .msg {
      font-family: $font-family-nunito-regular;
      color: #FF4B8C;
      font-size: 14px;
    }
  }
}
</style>
