export default {
  props: {
    /**
     * Whether the internal label is label tag
     * (due to accesibility control)
     */
    hasLabelTag: Boolean,

    /**
     * Field status
     */
    status: {
      type: String,
      default: 'none',
      validator: v => [ 'positive', 'negative', 'none', '' ].includes(v)
    }
  }
}
