import { extend } from 'vee-validate'
import { required, email, min, confirmed } from 'vee-validate/dist/rules'

export default () => {
  extend('email', {
    ...email,
    message: 'E-mail inválido.'
  })

  extend('required', {
    ...required,
    message: 'Campo obrigatório.'
  })

  extend('min', {
    ...min,
    message: 'No mínimo {length} caracteres.'
  })

  extend('full_name', {
    validate (value) {
      return (value.split(' ').length > 1 && value.split(' ')[1].length > 0)
    },

    message: 'Precisa de nome e sobrenome'
  })

  extend('password', {
    validate (value) {
      const chars = [ ...value ]
      const hasAlpha = chars.some(char => char.match(/[a-zA-Z]/i))
      const hasNum = chars.some(char => char.match(/[0-9]/i))

      return hasAlpha && hasNum
    },

    message: 'Senha não contém número ou letra'
  })

  extend('password_confirmation', {
    ...confirmed,
    message: 'Senhas devem ser iguais.'
  })
}
