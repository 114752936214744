<template>
  <validation-observer ref="observer" v-slot="observer" slim>
    <form
      novalidate
      class="form-builder"
      @submit.prevent="emitSubmit(observer)"
    >
      <slot name="before-fields" />

      <div class="fields">
        <field
          v-for="(field, fieldName) in fields"
          :key="fieldName"
          :has-validation="hasValidation"
          :value="value[fieldName]"
          :field="field"
          :name="fieldName"
          :raw.sync="raw[fieldName]"
          @input="inputValue => $emit('input', { ...value, [fieldName]: inputValue })"
          @update:raw="inputValue => $emit('update:raw', { ...raw, [fieldName]: inputValue })"
        />

        <slot name="extra-content" />
      </div>

      <slot name="after-fields" />
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import Field from './BuilderField'
import startRules from './startRules'

startRules()

export default {
  components: {
    ValidationObserver,
    Field,
  },

  data: () => ({ raw: {}, hasValidation: true }),

  props: {
    value: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true
    }
  },

  methods: {
    getFieldAttrs (field) {
      const {
        hasValidation,
        component,
        name,
        style,
        ...fieldAttrs
      } = field

      return fieldAttrs
    },

    getFullObserver (observer) {
      const { setErrors } = this.$refs.observer

      return { ...observer, setErrors }
    },

    emitSubmit (originalObserver) {
      const observer = this.getFullObserver(originalObserver)
      this.hasValidation = true
      this.$emit('submit', observer, this.value, this.raw)
    }
  }
}
</script>
